<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Orientation Assessment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex">
                <label class="col-form-label me-1">Program Name</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="options => options.id !== program.id"
                    @option:selected="
                      getBatchByProgram(program)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Batch</label>
                <div style="width: 150px">
                  <v-select
                    label="batch_no"
                    v-model="batch"
                    :options="batches"
                    :clearable="false"
                    :selectable="options => options.id !== batch.id"
                    @option:selected="
                     getEmployeeByBatch(program.id, batch.id)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Month</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="month"
                    :options="allMonths"
                    :clearable="false"
                    :selectable="options => options.id !== month.id"
                    @option:selected="fetchData"
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Assessor</label>
                <div style="width: 150px">
                  <v-select
                    label="assessor_name"
                    v-model="assessor"
                    :options="assessors"
                    :clearable="false"
                    :selectable="options => options.assessor_id !== employee.assessor_id"
                    @option:selected="fetchData"
                  >
                  </v-select>
                </div>

                <div @click="refreshData" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <DataTable2 
                v-if="tableData?.length > 0 && !loadingTable"
                :data="tableData"
                :columns="columns"
                :table="title"
                :loading="loadingTable"
                :totalRecords="totalRecords"
                :currentPage="currentPage"
                :pageSize="pageSize"
                @update:pagination="handlePagination"
              >
                <template v-slot:column-created_at="{ row }">
                  {{ formatMonth(row.created_at) }}
                </template>
              </DataTable2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { months } from "../../../utlis/months";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import DataTable2 from '@/components/shared/DataTable2.vue';

export default {
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      batches: [],
      allMonths: months,
      assessors: [],
      assessor: "",
      employee: "",
      month: "",
      program: "",
      batch: "",
      title: "Orientation Assessment Report Lists",
      tableData: [],
      columns: [
        { data: 'employee_id', title: 'Employee ID' },
        { data: 'employee_name', title: 'Employee Name' },
        { data: 'program_name', title: 'Program Name' },
        { data: 'batch_name', title: 'Batch' },
        { data: 'hrd_assessment_result', title: 'HRD Assessment Result' },
        { data: 'behavior_survey_result', title: 'Behavior Survey Result' },
        { data: 'evaluation_score', title: 'Final Result' },
        { data: 'assessor_name', title: 'Assessor Name' },
        { data: 'created_at', title: 'Month' },
      ],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      // searchQuery: '',
      ignoreNextEmit: false,
      headers: [
        "No.",
        "Employee ID",
        "Employee Name",
        "Program Name",
        "Batch",
        "HRD Assessment Result",
        "Behavior Survey Result",
        "Final Result",
        'Assessor Name',
        'Month'
      ],
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      loadingTable: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    DataTable2
  },
  methods: {
    async getBatchByProgram(program) {
      this.batch = "";
      this.assessor = "";
      this.employee = "";
      this.employeeLists = [];
      this.assessorList = [];
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`
        )
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(programId, batchId) {
      this.assessor = "";
      this.employeeLists = [];
      this.employee = "";
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/get-assessor-list/${programId}/${batchId}`
        )
        .then((response) => {
          this.assessors = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    formatMonth(date) {
      return moment(date).format('MMMM');
    },
    async getBatch() {
      this.loading = true;
      try {
        const response = await axios.get(`${this.baseUrl}admin/v1/program-batches/list`);
        this.batches = response.data.data;
      } catch {
        this.toast.error("Not Found Batch!");
      } finally {
        this.loading = false;
      }
    },
    async fetchTableData({ currentPage, pageSize }) {
      this.loading = true;
      this.loadingTable = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/orientation-assessment-report`, {
          params: {
            page: currentPage,
            per_page: pageSize,
            program_id: this.program.id ?? '',
            batch_id: this.batch.id ?? '',
            month: this.month.id ?? '',
            employee_id: this.employee.emp_id ?? '',
            // search: this.searchQuery,
          },
        });
        this.tableData = response.data.data;
        this.totalRecords = response.data.total;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
      } catch (error) {
        this.toast.error("Something went wrong.");
        console.error('Error fetching table data:', error);
      } finally {
        this.loading = false;
        this.loadingTable = false;
        this.$Progress.finish();
      }
    },
    async getWithoutPaginate(){
      this.loading = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/orientation-assessment-report`, {
          params: {
            program_id: this.program.id ?? '',
            batch_id: this.batch.id ?? '',
            month: this.month.id ?? '',
            employee_id: this.employee.emp_id ?? '',
          },
        });
        if(response){
          return response.data.data;
        }
      } catch (error) {
        this.toast.error("Something went wrong.");
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }

      return false;
    },
    fetchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      // this.searchQuery = '';
      this.fetchTableData({ currentPage: this.currentPage, pageSize: this.pageSize });
    },
    handlePagination({ currentPage, pageSize }) {
      if (this.ignoreNextEmit) {
        this.ignoreNextEmit = false;
        return;
      }
      if (this.pageSize !== pageSize) {
        this.currentPage = 1; // Reset to first page if page size changes
      }
      console.log(this.currentPage, this.pageSize);
      if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.fetchTableData({ currentPage, pageSize });
      }
    },
    async exportPdfClick() {
      const apiData = await this.getWithoutPaginate();
      if(apiData && apiData.length > 0){
        this.pdfLoading = true;
        const sheetData = apiData.map((item, index) => {
            let row = [];
            row[0] = index + 1;
            row[1] = item.employee_id;
            row[2] = item.employee_name;
            row[3] = item.program_name;
            row[4] = item.batch_name;
            row[5] = item.hrd_assessment_result;
            row[6] = item.behavior_survey_result;
            row[7] = item.evaluation_score;
            row[8] = item.assessor_name;
            row[9] = this.formatMonth(item.created_at);
          return row;
        });
        
        exportPdf(this.headers, sheetData, "Orientation-Assessment-Report");
        this.pdfLoading = false;
      }else{
        return this.toast.error("There is no data to export!");
      }
      
    },
    async exportExcelClick() {
      const apiData = await this.getWithoutPaginate();
      if (apiData && apiData.length > 0) {
        this.excelLoading = true;
        
        // Add headers as the first row
        const sheetData = [this.headers];

        // Add data rows
        apiData.forEach((item, index) => {
          sheetData.push([
            index + 1,
            item.employee_id,
            item.employee_name,
            item.program_name,
            item.batch_name,
            item.hrd_assessment_result,
            item.behavior_survey_result,
            item.evaluation_score,
            item.assessor_name,
            this.formatMonth(item.created_at)
          ]);
        });

        exportExcel(sheetData, "Orientation-Assessment-Report");
        this.excelLoading = false;
      } else {
        return this.toast.error("There is no data to export!");
      }
    },

    async refreshData() {
      this.program = "";
      this.batch = "";
      this.month = "";
      this.employee = "";
      // this.fetchData();
    },

  },
  async mounted() {
    // await this.getBatch();
    this.fetchTableData({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
    });
  },
  
};
</script>

<style scoped>
.icon-css {
  font-size: 18px;
  cursor: pointer;
  color: #4a81d4;
}
</style>
